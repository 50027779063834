import React from "react";
import Img from "gatsby-image"
import Link from "gatsby-link";
import {pages_transform} from "../../utils/pageutil"
import {just_excerpt} from "../../utils/textutil"


const NewsItem = (node,myclass, key , large = false)=>(
  <div key={key} className={`single-news col-sm-4 ${myclass}` }>
      <article>
          { node.featured_media ? (<Img className="limit-img-height" sizes={node.featured_media.childImageSharp.sizes} alt="" />) : null }
          <div className="content">
              <h5>{node.title}</h5>
              <p dangerouslySetInnerHTML={{ __html: just_excerpt(node.excerpt) }} />
              <span className="date">{node.date}</span>
          </div>
          <Link to={"/blog-"+node.post_id + ".html"} className="link"></Link>
      </article>
  </div>
);


const News = (raw_data) => (
  <div className="light-background">
    <div className="container">
      <div className="row no-margin padding-onlytop-md">
        <div className="col-md-12 padding-leftright-null text-center">
          <h3 className="big margin-bottom-small">最新動態</h3>
        </div>
        <div className="col-md-12 text" id="news">
          {/*Single News */ }
          { true ? pages_transform(raw_data).map( (news,i)=> {
              return NewsItem( news, "one-item", "item-"+i)
          }) : null }
          {/*END Single News */}
        </div>
      </div>
      <div className="row no-margin">
        <div className="col-md-12 padding-leftright-null">
          <div className="text padding-onlytop-sm padding-onlybottom-md padding-md-top-null">
          <Link to={"/blog"} className="secondary-color btn-pro">查看更多</Link>

          </div>
        </div>
      </div>
    </div>
  </div>
);

export default News;
