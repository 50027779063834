import React from "react";

const partners = ["must",
"namkwong",
"travel",
"fudan",
"1meeting",
"torch",
"school-pc",
"school-ls",
"mdc",
"park"];

const Partners = () => (
    <div className="container">
          <div className="row no-margin">
            <div className="col-md-12 padding-leftright-null">
              <div className="partners">
                <div className="col-xs-6 col-sm-4 col-md-2 text padding-leftright-null">
                  <h3 className="secondary-color">合作夥伴</h3>
                </div>
                { partners.map( (partner)=>(

                    <div className="col-xs-6 col-sm-4 col-md-1 partner">
                    <img
                      className="img-responsive-80"
                      src={ `assets/img/logo/${partner}-logo.png` }
                      alt=""
                    />
                    </div>

                  )) }
                
              </div>
            </div>
          </div>
        </div>
);

export default Partners;
