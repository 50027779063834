import React from "react";

const Video = () => (
  <div className="container">
    <div className="row no-margin padding-lg">
      <div className="col-md-7 padding-leftright-null">
        <div
          data-responsive="child-height"
          data-responsive-id="video"
          className="text padding-md-top-null height-auto-md"
        >
          {/*  Video Popup */}
          <a className="popup-vimeo" href="https://drive.google.com/file/d/1dGH75zhY57vf492LU1YEDU3UG8VKwuD9/preview">
            <img src="assets/img/video-guard.jpg" alt="" />
          </a>
          {/*  END Video Popup */}
        </div>
      </div>
      <div className="col-md-5 padding-leftright-null">
        <div
          data-responsive="parent-height"
          data-responsive-id="video"
          className="text padding-md-top-null padding-md-bottom-null"
        >
          <h2 className="margin-bottom-null left">
            天機1號影片
          </h2>
          <div className="padding-onlytop-sm">
            <p className="margin-bottom">
            天機1號商用服務機器人於201年8月正式推出市場,具有人臉識別、語音對話、視頻監控、室內室外機動行走等功能,適用於會展服務、考勤簽到、酒店服務、物業安防等場景。
            </p>
            <ul className="styled">
              <li>自動巡邏</li>
              <li>遠程控制</li>
              <li>遠程後台對答</li>
              <li>微信掃一掃拿照片</li>
              <li>監控錄像回放</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Video;
