import React from "react";
import Link from "gatsby-link";
import "./index/index.less";

import Partners from "./index/_partners";
import Clients from "./index/_clients";
import News from "./index/_news";
//import Feedback from "./index/_feedback";
import Video from "./index/_video";
//import Features from "./index/_features";

import GuardImage from "./img/hero-guard-green.jpg"
import ButlerImage from "./img/hero-butler-text.jpg"
import Layout from "../components/layout"

const IndexPage = ({data} ) => (
  <Layout>
  <div id="main-wrap">
    {/*   Main Wrap  */ }
    {/*   Page Content  */}
    <div id="page-content" className="header-static">
      {/*   Slider  */}
      <div className="fullpage-wrap small">
        <div className="hero-1">
        <Link to="/guard">
          <img
            className="img-responsive"
            src={GuardImage}
            alt=""
          />
          </Link>
        </div>
        <div className="down-wrapper">
          <div id="godown">
            <a href="#home-wrap" className="btn-down">
              <i className="material-icons">keyboard_arrow_down</i>
            </a>
          </div>
        </div>
        <div className="hero-2">
        <Link to="/butler">
          <img
            className="img-responsive"
            src={ButlerImage}
            alt=""
          />
          </Link>

        </div>
      </div>
      {/*   END Slider  */}
      <div id="home-wrap" className="content-section fullpage-wrap">
        {/*  CTA */}
        <div className="secondary-background">
          <div className="container">
            {/*  Section Image */}
            <div className="row no-margin">
              <div className="col-md-9 padding-leftright-null">
                <div className="text text-md-center">
                  <h2 className="white small text-spacing-50">
                    安信通只做最好的服務機器人
                  </h2>
                </div>
              </div>
              <div className="col-md-3 padding-leftright-null">
                <div className="text text-right padding-md-top-null text-md-center">
                <Link to="/butler" className="btn-alt active small white margin-null">了解更多</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  END CTA */}
        {/*  Features Section */}
        {/* { Features() } */}
        {/*  END Features Section */}

        {/*  Video Section */}
        {Video()}
        {/*  END Video Section */}
        {/*   Section Testimonials  */}
        {/* { Feedback() } */}
        {/*  END Section Testimonials  */}
        {/* Section News */}
        {News(data)}
        {/* END Section News */}
        {/*  Section Partners */}
        {Partners()}
        {/*  END Section Partners */}
        {/*  Section Partners */}
        {Clients()}
        {/*  END Section Partners */}
      </div>
    </div>
    {/*   END Page Content */}
    {/*   END Main Wrap  */}
  </div>
  </Layout>
);

export const query = graphql`
query index{
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit:3){
    edges{
      node{
      	... pageFields
      }
    }
  }
}
   
`

//   export const query = graphql`
//   query index {
//         allWordpressPost(limit:3) {
//           edges {
//             node {
//               id
//               slug
//               title
//               wordpress_id
//               featured_media {
//                 localFile {
//                   childImageSharp {
//                       sizes: sizes(maxWidth: 800, maxHeight: 450, cropFocus: CENTER) {
//                         ...GatsbyImageSharpSizes_withWebp
//                       }
//                   }
//                 }
//               } 
//               excerpt
//               date(formatString: "YYYY年MMMD日", locale: "zh-TW")
//               modified(formatString: "YYYY年MMMD日", locale: "zh-TW")
//             }
//           }
//         }
//   }
     
// `

;

export default IndexPage;
