import React from "react";

const clients = ["msc", "travel", "1meeting", "sk", "global-edu"];

const Clients = () => (
  <div className="light-background">
    <div className="container">
      <div className="row no-margin">
        <div className="col-md-12 padding-leftright-null">
          <div className="partners">
            <div className="col-xs-6 col-sm-4 col-md-2 text padding-leftright-null">
              <h3 className="secondary-color">合作客戶</h3>
            </div>
            {clients.map(item => (
              <div className="col-xs-6 col-sm-4 col-md-1 partner">
                <img
                  className="img-responsive-80"
                  src={`assets/img/logo/${item}-logo.png`}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Clients;
